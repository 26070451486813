import {presetPalettes} from '@ant-design/colors';
import {grey}           from '@mui/material/colors';
import {createTheme}    from '@mui/material/styles';
import ThemeOption      from 'shared/ui/themes/theme';

export const ebonyClay  = '#232e44';
export const blueZodiac = '#112a46';
// export const alabaster  = '#fafafa';
export const alabaster  = '#d1e8fd';
const Red               = '#e9534e';
const Black             = '#000';
const White             = '#fff';
const MainDark1         = '#011334';
const MainDark2         = '#3d80f3';

export const TableBorder        = '1px solid';
export const TableBorderColor   = 'rgba(17,42,70,0.39)'; // From blueZodiac diff transparency
// export const TableHeaderBgColor = MainDark1;
export const TableHeaderBgColor = 'rgba(1,19,52,0.88)';
export const TableHeaderColor   = alabaster;
export const greyDefault        = '#fafafb';
export const colorBase          = {
    Black,
    White,
    ebonyClay,
    blueZodiac,
    alabaster,
    Red,
    MainDark1,
    MainDark2
};

const Palette = (mode: any) => {
    let colors         = presetPalettes;
    const greyPrimary  = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ];
    const greyAscent   = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    const greyConstant = [greyDefault, '#e6ebf1'];

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const paletteColor = ThemeOption(colors);

    return createTheme({
        palette: {
            mode,
            common: {
                black: Black,
                white: White
            },
            ...paletteColor,
            text      : {
                primary  : paletteColor.grey[700],
                secondary: paletteColor.grey[500],
                disabled : paletteColor.grey[400]
            },
            action    : {
                disabled: paletteColor.grey[300]
            },
            divider   : paletteColor.grey[200],
            background: {
                paper: paletteColor.grey[0],
                // default: paletteColor.grey.A50
                default: greyDefault
                // default: paletteColor.grey.A100
            }
        }
    });
};

export default Palette;
